import config from "src/config"

export default class RedirectError extends Error {
  readonly path
  constructor(path: string) {
    const _path =
      path.startsWith("/") || path.startsWith(config().server.dashboardBaseUrl) ? path : "/"
    super(`RedirectError: Redirecting to ${_path}`)
    this.path = _path
  }
}
