"use client"

import useRedirectError from "hooks/useRedirectError"
import { PageError, PageErrorProps } from "src/app/_components/pageError/pageError"

export default function Error({ error, reset }: PageErrorProps) {
  const isRedirectError = useRedirectError(error)

  if (isRedirectError) {
    return null
  }

  return <PageError error={error} reset={reset} className="mt-60" />
}
