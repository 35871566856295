"use client"

import { datadogRum } from "@datadog/browser-rum"
import clsx from "clsx"
import { InfoIcon } from "components/icon/InfoIcon"
import { useSession } from "next-auth/react"
import { useEffect } from "react"
import { Button } from "src/orca/components/Button/Button"

export type PageErrorProps = {
  error: Error & { digest?: string }
  reset?: () => void
  className?: string
}

export const PageError = ({ error, reset, className }: PageErrorProps) => {
  useEffect(() => {
    // temporary workaround, we don't need to log if reset is not present because
    // it means the error did not come from next.js and it was already logged
    if (reset) {
      datadogRum.addError(error)
    }
  }, [error, reset])
  const userOrgId = useSession().data?.organizationId

  return (
    <div className={clsx("mt-40 flex justify-center font-medium", className)}>
      <div className="box-border gap-2 flex w-80 flex-col items-center rounded-3xl border border-divider p-4">
        <InfoIcon size="1.5rem" className="my-2" />
        <h2>{error.message || "Something went wrong!"}</h2>
        {error.name === "499" ? (
          <Button
            variant="secondary"
            onClick={() => location.replace("/settings/user?2FANotice=true")}
          >
            Go to settings page
          </Button>
        ) : (
          <>
            {userOrgId && (
              <Button variant="secondary" onClick={() => location.replace(`/org/${userOrgId}`)}>
                Go back to your organization
              </Button>
            )}
            <div className="flex gap-4">
              {reset && (
                <Button variant="secondary" onClick={() => reset()}>
                  Try again
                </Button>
              )}
              <Button variant="secondary" onClick={() => location.replace(location.pathname)}>
                Refresh
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
