"use client"

import { usePathname, useRouter } from "next/navigation"
import { useEffect } from "react"
import RedirectError from "src/errors/RedirectError"

const useRedirectError = (error: unknown) => {
  const pathname = usePathname()
  const router = useRouter()
  const isRedirectError = error instanceof RedirectError
  const shouldRedirect = isRedirectError
    ? (pathname.startsWith("/auth") && !error.path.startsWith("/auth")) || pathname !== error.path
    : false

  useEffect(() => {
    if (shouldRedirect) {
      router.push((error as RedirectError).path)
    }
  })

  return isRedirectError
}

export default useRedirectError
